

export const photos = [
    {
        src: require('./0a3473eb-27c1-49bb-9461-67ac14b2a2bb.jpg'),
        width: 4,
        height: 3
    },
    {
        src: require('./1a362321-9b78-42a4-ba60-bdc5a75479f6.jpg'),
        width: 1,
        height: 1
    },
    {
        src: require('./1cdca10a-9303-4960-84d9-8bb23272630c.jpg'),
        width: 3,
        height: 4
    },
    {
        src: require('./5f36b2e5-b93d-4c41-bab2-d94f1b884bf9.jpg'),
        width: 3,
        height: 4
    },
    {
        src: require('./6c73e461-9dee-4e61-bb90-7e42bbb5d81f.jpg'),
        width: 3,
        height: 4
    },
    {
        src: require('./7c42e9e1-8d99-4ff8-af0a-936f5fdab747.jpg'),
        width: 3,
        height: 4
    },
    {
        src: require('./32a7c006-7784-489c-94c6-9add34326a23.jpg'),
        width: 3,
        height: 4
    },
    {
        src: require('./39c20c32-846f-4c73-a6ee-34885d67e3ce.jpg'),
        width: 3,
        height: 3
    },
    {
        src: require('./46dc8705-e316-4a48-86c0-846b9a9848cf.jpg'),
        width: 3,
        height: 4
    },
    {
        src: require('./86a7be1e-4deb-4117-9cdc-4ea1402c948f.jpg'),
        width: 3,
        height: 4
    },
    {
        src: require('./283b971e-061c-4466-91c8-5072eef0e125.jpg'),
        width: 3,
        height: 4
    },
    {
        src: require('./287ea8be-8543-49f5-9883-58e9bc78ca2e.jpg'),
        width: 4,
        height: 3
    },
    {
        src: require('./0291a58c-55a2-40f0-a943-749fde53574f.jpg'),
        width: 3,
        height: 4
    },
    {
        src: require('./951e7802-0433-4924-868c-71204174c6d5.jpg'),
        width: 3,
        height: 4
    },
    {
        src: require('./4735b3b3-a290-4fd0-b8af-3b64fceb965b.jpg'),
        width: 3,
        height: 4
    },
    {
        src: require('./4909c782-2e3d-4c1c-ae03-87f51e4f8716.jpg'),
        width: 4,
        height: 3
    },
    {
        src: require('./a1bcfb39-793e-4b6e-a05a-4e7b82ebbfc4.jpg'),
        width: 3,
        height: 4
    },
    {
        src: require('./Aslan.jpg'),
        width: 4,
        height: 3
    },
    {
        src: require('./bea93e59-b845-4ad5-b49f-329c30f1dbce.jpg'),
        width: 3,
        height: 4
    },
    {
        src: require('./cc775110-ccbb-457c-b26a-525d90e3fe0f.jpg'),
        width: 4,
        height: 3
    },
    {
        src: require('./d60e7501-b415-4d05-ada4-a5050a8c0704.jpg'),
        width: 4,
        height: 3
    },
    {
        src: require('./fcdc999d-a069-4faf-b0cf-f29a78e38ad3.jpg'),
        width: 4,
        height: 3
    },
    {
        src: require('./IMG_1151.jpg'),
        width: 3,
        height: 4
    },
    {
        src: require('./IMG-20180728-WA0172.jpg'),
        width: 3,
        height: 4
    },
    {
        src: require('./IMG-20180729-WA0047.jpg'),
        width: 4,
        height: 3
    },
    {
        src: require('./IMG-20180730-WA0206.jpg'),
        width: 4,
        height: 3
    },
    {
        src: require('./IMG-20180924-WA0090.jpg'),
        width: 3,
        height: 4
    },
    {
        src: require('./IMG-20181008-WA0106.jpg'),
        width: 3,
        height: 4
    },
    {
        src: require('./IMG-20181013-WA0001.jpg'),
        width: 3,
        height: 4
    },
    {
        src: require('./IMG-20181016-WA0105.jpg'),
        width: 3,
        height: 4
    },
    {
        src: require('./IMG-20181023-WA0003-1.jpg'),
        width: 3,
        height: 4
    },   
    {
        src: require('./IMG-20181215-WA0010.jpg'),
        width: 3,
        height: 4
    },  
    {
        src: require('./Marsbar.jpg'),
        width: 2,
        height: 4
    },  
    {
        src: require('./pups-F3.jpg'),
        width: 4,
        height: 2
    },
    {
        src: require('./Ruby-pup.jpg'),
        width: 2,
        height: 4
    }
];
import React from "react";
import "./Content.css";

export default function Aboutus() {
  return (
    <div className="Home">
      <div className="lander">
        <h2>About Us</h2>
        <div className="labtext">
        <p>Eight years ago we arrived back in South Africa having spent the previous decade in the UK. We were there when everyone was asking Labra … what? You’ve got to be kidding! We were there when celebrities, such as Richard Hammond and Graham Norton, were getting their Labradoodles. We were there when the press was going mad coining the phrase ‘a designer dog’. By the time we left some sanity had settled around the business of Labradoodles! When we took our girl’s for a walk people were recognising them as Labradoodles and had finally stopped saying Labra …. what? Now in the UK, USA and Australia, a visit to a park will more than likely entail bumping into a Labradoodle, they have become that popular.</p>
        <p></p>
        <p>Hence our surprise when we arrived here and no one had heard of them. Times have changed in these last eight years and we now are having many inquiries from families looking for a Labradoodle. Their popularity has finally reached South Africa.</p>
        <p>We have bred Labradoodles for fourteen years and have been involved in breeding other breeds prior to that. Our dogs are first and foremost our family pets and we feel we have found the perfect breed, not because they are the ‘in’ dog, but because of their temperament, non shedding and joy to be around. We have certainly found our preferred breed. Our puppies are now living all around the world; Namibia, Malawi, UK, Germany, Holland, Hong Kong, the USA and all corners of South Africa.</p>
        </div>
      </div>
    </div>
  );
}
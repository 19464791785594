import React from "react";
import "./Content.css";

export default function Puppies() {
  return (
    <div className="Home">
      <div className="lander">
        <h2>Puppies</h2>
        <div className="labtext">
          <p>Our puppies are born and raised in our home with all the noise and activity of family life. A MagicLabradoodle puppy is fed well, socialised well and comes from an excellent lineage. MagicLabradoodles are the ‘perfect’ addition to a family looking for a family pet.</p>
          <p>Anyone wanting a Labradoodle from us will currently have a wait, the soonest you get your name on the list, the earliest we will be able to get you a puppy.</p>
          <p>How to get onto our waiting list. Email info@labradoodle.co.za with a paragraph on your setup (children, other pets etc); what you are looking for (size, colour, sex) the more flexible the sooner we will have a puppy for you; and please don’t forget to include your full contact details. If you are committed to owning one of our Labradoodle pups, would like to be kept up to date on your position on your chosen list, freeze any price increases for 24 months, then please ask about the Elite List. The price for our puppies are determined at the birth of the litter, depending on the costs involved. A R1500 deposit will secure a position on the list of your choice.</p>
          <p>We welcome you to visit the puppies, weekly, and at around 5 weeks you will get to choose your puppy so that when you visit us, from this point onward, you get to play with your puppy on its own. At this time we ask you to leave with us a small blanket, t-shirt or pillow case with your family smells on it. This item is introduced to the litter a day before they leave us to come to you, so their litter smells are added into the smells pot!</p>
          <p>Your puppy will be ready to leave us at 8 weeks old. We will have already prepared them for their car journey as we take them out in the car a few times leading up to them leaving us. He/She will have had their first inoculation, and will have been checked over by our vet. Your puppy will leave us with food, a diet sheet, a settling in sheet and all his/ her paperwork. He/She will also leave with the now very smelly blanket/t-shirt/pillow case, which we ask that you please keep it close to them for the first few weeks to help them in their settling in period into their forever home.</p>
          <p>Please contact us if you have any questions. We welcome you to come and visit us and our dogs before making up your mind whether the Labradoodle is the dog for your family.</p>
        </div>
      </div>
    </div>
  );
}
import React from "react";
import Carousel from 'react-bootstrap/Carousel'
import "./Content.css";
import cimage1 from '../images/slider-backgrounda-1024x531.jpg';
import cimage2 from '../images/slider-backgroundb-1024x543.jpg';
import cimage3 from '../images/slider-backgroundc-1024x531.jpg';
import cimage4 from '../images/slider-backgroundd-1024x543.jpg';
import cimage5 from '../images/slider-backgrounde-1024x543.jpg';

export default function Home() {
  return (
    <div className="Home">
      <div className="lander">
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={cimage1}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={cimage2}
              alt="second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={cimage3}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={cimage4}
              alt="Forth slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={cimage5}
              alt="Fith slide"
            />
          </Carousel.Item>
        </Carousel>
        <p> </p>
        <div className="labtext">
          <p>Labradoodles certainly tick all the boxes when it comes to being ‘the perfect family dog’. They are highly intelligent, gentle, calm, fun-loving, non-aggressive, cheeky and mainly non-shedding. In the last ten years we have seen them rapidly grow in popularity around the world.</p>
          <p>As the name suggests they are a cross between, the world’s most popular family pet, the Labrador, and the highly intelligent Poodle hence the name Labra…doodle. They vary in size, colour and coat, yet their temperament is uniquely Labradoodle. Many say that once you’ve lived with a Labradoodle you could never have another breed of dog.</p>
          <p>In some circles, Labradoodles are still occasionally condescendingly referred to as ‘only a crossbreed’; and well, who am I to argue! But it is worth highlighting that all registered purebred dogs initially began their life as crossbreeds. Considering the numbers of very happy Labradoodle families, around the world, then we believe the Labradoodle has most certainly earned its place to be called a breed in its own right.</p>
          <p>MagicLabradoodles were part of the UK Labradoodle boom. We have been breeding Labradoodles for the last fourteen years, six of which were in the UK. Eight years ago we returned ‘home’, along with Bluebell, our black, standard Labradoodle and Sweetpea, our apricot, miniature Labradoodle. Our girl’s have strong lineage’s from the UK, South Africa and the international Poodle world, as well as having working Labrador lineage. Boasting ancestors of 40 + champions as well as many field trial champions.</p>
          <p>We have taken care in our breeding program to ensure that a puppy from MagicLabradoodle is healthy, happy and of the best nature.</p>
          <p>There is a waiting list – please contact us for further information.</p>
          <h3>Famous Labradoodles and Famous Labradoodles Owners</h3>
          <p>Olate Labradoodles won America’s Got Talent 2012<br />
        Bocker is a famous Labradoodle actdog. Acting in Eat Pray Love and Men in Black 3<br />
        Jeremy Clarkeson and Richard Hammond both own Labradoodles. Richard’s Labradoodle became the Top Gear Dog appearing in several episodes of Top Gear as well<br />
        as in an advert with Richard Hammond.<br />
        Jennifer Aniston has a Labradoodle that was bought for her by her ex-husband Brad Pitt<br />
        Tiger Woods has two Labradoodles which he has named Yogi and Riley<br />
        Jeremy Irons has two black Labradoodles<br />
        Christie Brinkley has a Labradoodle called Maple Sugar<br />
        Lady Gagga and apparently Charlotte Church live with Labradoodles, the list goes on ….<br />
          </p>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "./Content.css";
import { Accordion, Card, Button, Row, Col, Image } from 'react-bootstrap';
import coat1 from '../images/woollen-coat1024.jpg';
import coat2 from '../images/Fleece-coat1024.jpg';
import coat3 from '../images/Scruffy-Coat1024.jpg';

export default function History() {
  return (
    <div className="Home">
      <div className="lander">
        <h2>About Labradoodles</h2><p />
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                <h3>History</h3>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body><div className="labtext"><p>Labradoodles originate from Australia. Wally Cochran initially bred them, during the 1970’s, as guide dogs for allergy sufferers. The aim of the breed was to produce a dog with all the characteristics of the gentle, easy going, intelligent, highly trainable Labrador without the unfortunate habit of coat shedding. He settled on the highly intelligent Poodle, with its woolen coat, to compliment the Labrador and so the Labradoodle was conceived. The Labradoodle takes the best of both originating breeds and makes the ideal family pet.</p></div></Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="1">
                <h3>Coat</h3>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body><div className="labtext">
                <p>Because it is still relatively early days in the development of this breed, the coat varies in each litter from:</p>
                <Row>
                  <Col sm={3}>
                    <Row>
                      <Image src={coat1} thumbnail />
                    </Row>
                    <Row>
                      <div className="labtext">
                        <p>a woolen coat which is curly, non-shedding and requires regular grooming</p>
                      </div>
                    </Row>
                  </Col>
                  <Col sm={1}/>
                  <Col sm={3}>
                    <Row><Image src={coat2} thumbnail /></Row>
                    <Row>
                      <div className="labtext">
                        <p>a fleece coat which is non-shedding and requires regular grooming</p>
                      </div>
                    </Row>
                  </Col>
                  <Col sm={1}/>
                  <Col sm={3}>
                    <Row><Image src={coat3} thumbnail /></Row>
                    <Row>
                      <div className="labtext">
                        <p>a scruffy coat which is mainly non-shedding and requires little to no grooming</p>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="2">
                <h3>Size</h3>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body><div className="labtext">
                <p>There are two sizes of Labradoodles; Miniature and Standard. These sizes are dependent on what size poodle the pups are bred from; Miniature Labradoodles are bred from Miniature Poodles and Standard Labradoodles are bred from Standard Poodles. There is a range in size of the pups when fully grown and these are all down to which genes the pup has inherited. The top end of miniatures and the bottom end of Standards overlap and are sometimes referred to as medium size labradoodles. Males are generally bigger than females.</p>
                <ul>
                  <li>Miniature size. Measures aprox on average 44 – 52 cm’s shoulder height. Weights aprox on average 12 to 20 kg’s</li>
                  <li>Standard size. Measures aprox on average 53 – 58 cm’s shoulder height. Weights aprox on average 22 to 30 kg’s</li>
                </ul>
              </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="3">
                <h3>Generations</h3>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body><div className="labtext">
                <p>Much is mentioned about F1, F1B, F2, F3 and multi-generation Labradoodles, so here is a break down of what these mean:</p>
                <ul>
                  <li>F1, first generation, Labrador X Poodle. Pups produced are mainly scruffy coated with some woollen and some flat coated pups. Standard size is pretty consistent. The miniature sizes vary depending on what gene the pups inherit.</li>
                  <li>F1B, F1 back-bred to a Poodle normally, and in rare instances a Labrador but this is not recommended, the reasoning is to strengthen the coat for allergy sufferers. Pups produced have mainly fleece or woollen coats, with the occasional scruffy coated pups. Size varies depending on what gene the pups inherit. A backbred litter can occur at any level so there are F2B’s, F3B’s etc. </li>
                  <li>F2, second generation, F1 Labradoodle X F1 Labradoodle. Pups produced have the widest variation in coat. Coats to expect are woollen, fleece, flat-coated and scruffy. Size varies depending on what gene the pup inherits.</li>
                  <li>F3, third generation, F2 Labradoodle x F2 Labradoodle. Pups produced are starting to settle down in coat. Coats are mainly fleece or woollen with the occasional scruffy. Size varies depending on what genes the pups inherit.</li>
                  <li>Multi-generation, when F3 Labradoodles, and more, are bred together. The coats seem to settle down offering mainly fleece and woollen coats. Very occasionally there is a throw back flat coat or scruffy coat.</li>
                </ul>
              </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="4">
                <h3>Temperament</h3>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
              <Card.Body><div className="labtext">
                <p>The Labradoodle has developed into a success not only as guide dogs but as a highly sought after family pet. Its success is not surprising because of its lovable personality and low to non-shedding coat. The words that are used most often attributed to a Labradoodle are; confident, clever, loving and loveable, vivacious, loyal, affectionate, joyful, sociable, friendly, comical, intuitive, totally non-aggressive and devoted.</p>
                <p>Not surprisingly they love water, but this can vary depending on their individual personality. They love being part of your life and get very lonely if they are left by themselves all day long, so won’t suit a family working away for the whole day.</p>
                <p>A few of our pups, from our previous litters, have gone on to be working dogs and the feedback that we’ve received is that they are highly trainable.</p>
              </div></Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>
  );
}
import React from "react";
import "./Content.css";
import { Row, Col, Image } from 'react-bootstrap';
import bluebell from '../images/bluebell.jpg';
import sweetpea from '../images/sweetpea.jpg';
import sparkle from '../images/sparkle.jpg';
import jasmine from '../images/jasmine.jpg';
import princessleia from '../images/princessleia.jpg';
import winter from '../images/winter.jpg';
import honey from '../images/honey.jpg';
import minicooper from '../images/minicooper.jpg';

export default function Ourlabs() {
  return (
    <div className="Home">
      <div className="lander">
        <h2>Our Labradoodles</h2><p />
        <Row>
          <Col sm={3}>
            <Row>
              <Image src={bluebell} thumbnail />
            </Row>
            <Row><p /><h3>Bluebell</h3><p /></Row>
            <Row>
              <div className="labtext">
                <p>Our retired grandma, standard, F1, scruffy, Labradoodle. Her mother was a black Labrador from working stock, her grandfather was field trial champion in the UK. Her Father was a white standard Poodle with 36 champions in his pedigree. Both Jessie and her Dad have amazing temperaments which have been passed on to Bluebell. Bluebell is extremely clever. She is gentle with children, loves to play and works out ingeneous ways to get us to do what she wants! She is also a great guard dog because of her size and colour, when she barks she can be quite intimidating if you don’t know her. Bluebell is the first to play with our puppies.</p>
              </div>
            </Row>
          </Col>
          <Col sm={1} />
          <Col sm={3}>
            <Row><Image src={sweetpea} thumbnail /></Row>
            <Row><p /><h3>Sweetpea</h3><p /></Row>
            <Row>
              <div className="labtext">
                <p>Our retired cream, miniature, F1B, woolly, Labradoodle. She started our miniature line. When she was a puppy she was the calmest puppy we’ve ever raised. Her Mother is Bluebell, and she was back bred to an apricot miniature Poodle from excellent lineage line in the UK. She has been described by others as the calmest puppy/dog in the world! She has the gentle, placid Labrador nature with the Poodle coat. As well as this she can be playful, and is great with children. Her puppies averaged around knee height when fully grown. </p>
              </div>
            </Row>
          </Col>
          <Col sm={1} />
          <Col sm={3}>
            <Row><Image src={sparkle} thumbnail /></Row>
            <Row><p /><h3>Sparkle</h3><p /></Row>
            <Row>
              <div className="labtext">
                <p>Our F2 chalk standard labradoodle. Parents Sacy and Albert. She is outgoing, bouncy and confident. Such a bundle of joy to be around. Sparkle is a beautiful mom. She has been mated with Pie and they produced a stunning litter of chalk, caramel, cream and the first in the country caramel ice.</p>
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Row>
              <Image src={jasmine} thumbnail />
            </Row>
            <Row><p /><h3>Jasmine</h3><p /></Row>
            <Row>
              <div className="labtext">
                <p>Jasmine F2 (black) woollen coat medium labradoodle. Her mom is Sweetpea and her dad is Albert. She produced a stunning litter of chocolate and black miniature labradoodles with a range of fleece and woollen coats. Jasmine is a gorgeous sensitive, gentle girl and is an excellent mom. Her mom is Sweetpea and dad is Albert. we are looking forward to her next litter.</p>
              </div>
            </Row>
          </Col>
          <Col sm={1} />
          <Col sm={3}>
            <Row><Image src={princessleia} thumbnail /></Row>
            <Row><p /><h3>Princess Leia</h3><p /></Row>
            <Row>
              <div className="labtext">
                <p>Our beautiful F3 apricot miniature labradoodle. Her mom is Peaches and her dad is George. She is a friendly, playful, happy and confident girl. She is due to have her first litter and I am very excited to see what she produces.</p>
              </div>
            </Row>
          </Col>
          <Col sm={1} />
          <Col sm={3}>
            <Row><Image src={winter} thumbnail /></Row>
            <Row><p /><h3>Winter</h3><p /></Row>
            <Row>
              <div className="labtext">
                <p>Our stunning caramel ice F3 standard labradoodle. Her mom is Sparkle and her dad is Pie. Winter is a gentle soul, she has to make sure you are to be trusted before she gives love, and wow when you are blessed it is the best feeling ever. She is still young and yet to produce her first litter. Winter will produce the first multigeneration labradoodles in South Africa.</p>
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Row>
              <Image src={honey} thumbnail />
            </Row>
            <Row><p /><h3>Honey</h3><p /></Row>
            <Row>
              <div className="labtext">
                <p>Our F3 apricot miniature fleece coated labradoodle. Her mom is Lunar and her dad is George. Honey is a beautiful soul a gentle, loyal, independent, watcher. She is smaller than knee height and will be having her first litter. These pups will also be multigen labradoodles. We are very excited for this litter.</p>
              </div>
            </Row>
          </Col>
          <Col sm={1} />
          <Col sm={3}>
            <Row><Image src={minicooper} thumbnail /></Row>
            <Row><p /><h3>Mini Cooper</h3><p /></Row>
            <Row>
              <div className="labtext">
                <p>Is our F2 medium/standard black woollen coat labradoodle. Her mom is Sacy and her dad is Albert. She is a gorgeous, easy going, happy girl. She is the first labradoodle I have met that can’t jump into the whelping box. Her first litter is a gorgeous mixture of black, silver, caramel, caramel ice, chalk and cream. Mini is an attentive and caring mom.</p>
              </div>
            </Row>
          </Col>
          <Col sm={1} />
          <Col sm={3}>
            <Row></Row>
            <Row></Row>
            <Row>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}
import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import History from "./pages/History";
import Contact from "./pages/Contact";
import Ourlabs from "./pages/Ourlabs";
import Photogallery from "./pages/Photogallery";
import Breeding from "./pages/Breeding";
import Puppies from "./pages/Puppies";
import Aboutus from "./pages/Aboutus";
import Admin from "./pages/Admin";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/history">
        <History />
      </Route>
      <Route exact path="/ourlabs">
        <Ourlabs />
      </Route>
      <Route exact path="/gallery">
        <Photogallery />
      </Route>
      <Route exact path="/breeding">
        <Breeding />
      </Route>
      <Route exact path="/puppies">
        <Puppies />
      </Route>
      <Route exact path="/aboutus">
        <Aboutus />
      </Route>
      <Route exact path="/contact">
        <Contact />
      </Route>
      <Route exact path="/admin">
        <Admin />
      </Route>
    </Switch>
  );
}
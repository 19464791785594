import React from "react";
import "./Content.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import dogzcreche from '../images/dogzcreche.png';
import rawfoods from '../images/justrawr.png';
import dogzwag from '../images/dogzwag.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faGlobe } from '@fortawesome/free-solid-svg-icons'

export default function Sponsors() {
    return (
        <div className="Sponsors">
            <div className="lander">
                <Container fluid>
                    <Row align="center">
                        <Col><p />
                        </Col>
                    </Row>
                    <Row align="center">
                        <Col>
                            <h2>Our Sponsors</h2>
                        </Col>
                    </Row>
                    <Row align="center">
                        <Col><p />
                        </Col>
                    </Row>
                    <Row  xs={1} md={2} lg={3}>
                        <Col>
                            {/* <Row>
                                <Image src={dogzwag} fluid />
                            </Row>
                            <Row>
                                <FontAwesomeIcon className="labtext" icon={faPhone} /><div className="labtext">&nbsp;+27 64 602 3157</div>
                            </Row>
                            <Row>
                                <FontAwesomeIcon className="labtext" icon={faEnvelope} /><div className="labtext">&nbsp;claire@dogzwag.com</div>
                            </Row>
                            <Row>
                                <FontAwesomeIcon className="labtext" icon={faGlobe} /><div className="labtext">&nbsp;www.dogzwag.com</div>
                            </Row> */}
                        </Col>
                        <Col>
                            <Row>
                                <Image src={rawfoods} fluid />
                            </Row>
                            {/* <Row> */}
                                {/* <FontAwesomeIcon className="labtext" icon={faPhone} /><div className="labtext">&nbsp;+27 83 300 7934</div> */}
                                {/* <FontAwesomeIcon className="labtext" icon={faPhone} /><div className="labtext"></div> */}
                            {/* </Row> */}
                            {/* <Row> */}
                                {/* <FontAwesomeIcon className="labtext" icon={faEnvelope} /><div className="labtext">&nbsp;info@justrawr.com</div> */}
                                {/* <FontAwesomeIcon className="labtext" icon={faEnvelope} /><div className="labtext"></div> */}
                            {/* </Row> */}
                            <Row>
                                <FontAwesomeIcon className="labtext" icon={faGlobe} /><div className="labtext">&nbsp;www.justrawr.com</div>
                            </Row>
                        </Col>
                        <Col >
                            {/* <Row>
                                <Image src={dogzcreche} fluid />
                            </Row>
                            <Row>
                                <FontAwesomeIcon className="labtext" icon={faPhone} /><div className="labtext" fluid>&nbsp;+27 64 602 3157</div>
                            </Row>
                            <Row>
                                <FontAwesomeIcon className="labtext" icon={faEnvelope} /><div className="labtext">&nbsp;info@labradoodles.co.za</div>
                            </Row>
                            <Row>
                                <FontAwesomeIcon className="labtext" icon={faGlobe} /><div className="labtext">&nbsp;www.dogzcreche.co.za</div>
                            </Row> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}
import React from 'react';
import ReactGA from 'react-ga';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import { Link } from "react-router-dom";
import { Nav, Navbar, Row, Image } from "react-bootstrap";
import CookieConsent from "react-cookie-consent";
import './App.css';
import './pages/Contact';
import Routes from "./Routes";
import logo from './images/logo5.png';
import Sponsors from './pages/Sponsors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt, faUserLock } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

function App() {
  ReactGA.initialize('UA-143783480-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div className="App">
      <Container className="p-3">
        <Row className="socialIcons">
          <a className="socialIcons" href="https://www.facebook.com/LabradoodlesForAfrica"><FontAwesomeIcon icon={faFacebook} size="2x" /></a>
          <a className="socialIcons" href="https://www.twitter.com/labradoodles_SA"><FontAwesomeIcon icon={faTwitter} size="2x" /></a>
          <a className="socialIcons" href="https://www.instagram.com/claire_madgwick"><FontAwesomeIcon icon={faInstagram} size="2x" /></a>
          <a className="socialIcons" href="mailto:claire@labradoodles.co.za"><FontAwesomeIcon icon={faAt} size="2x" /></a>
          <Link className="socialIcons" to="/admin"><FontAwesomeIcon icon={faUserLock} size="2x" /></Link>
        </Row>
        <Image src={logo} fluid />
        <Jumbotron>
        <Container>
          <Navbar expand="lg">
            <Nav fluid>
              <Nav.Link id="nav-link" as={Link} to="/">Home</Nav.Link>
              <Nav.Link id="nav-link" as={Link} to="/history">About Labradoodles</Nav.Link>
              <Nav.Link id="nav-link" as={Link} to="/breeding">Breeding Approach</Nav.Link>
              <Nav.Link id="nav-link" as={Link} to="/ourlabs">Our Labradoodles</Nav.Link>
              <Nav.Link id="nav-link" as={Link} to="/gallery">Gallery</Nav.Link>
              <Nav.Link id="nav-link" as={Link} to="/puppies">Puppies</Nav.Link>
              <Nav.Link id="nav-link" as={Link} to="/aboutus">About Us</Nav.Link>
              <Nav.Link id="nav-link" as={Link} to="/contact">Contact</Nav.Link>
            </Nav>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </Navbar>
          </Container>
          <Routes />
          <Sponsors />
        </Jumbotron>
        <CookieConsent location="bottom"
          buttonText="I Understand"
          cookieName="MagiclabsConsentCookie">We use cookies to ensure that we give you the best experience on our website.</CookieConsent>
      </Container>
      <Container>
        some text
      </Container>
    </div>
  );
}

export default App;

import React from "react";
import "./Content.css";

export default function Breeding() {
  return (
    <div className="Home">
      <div className="lander">
        <h2>Breeding Approach</h2>
        <div className="labtext">
          <p>The aim of a good breeder is to breed a dog that is healthy and to become the best representation of its breed. Many of the illnesses that plague our modern day dog (skeletal diseases, allergies, heart problems, jaws misaligned, heads too big, noses too small therefore unable to breath properly, early blindness and so on) are believed to be the result of interbreeding or breeding in faults. Yet when we look into the breeding stats of those ‘dedicated’ breeders who only breed from healthy dogs (ie low hip score, no eye problems), we still don’t find these problems have been eradicated, as we might have expected. So we asked the question perhaps there is something else, in addition, that contributes towards the ill health of these dogs?</p>
          <p>Over the years we, as well as many other dedicated breeders of dogs, have investigated and tried varies different approaches. What we, at MagicLabradoodles, have finally settled on is a combination of things which we feel are the important factors in breeding healthy dogs;</p>
          <ul>
            <li>Healthy parents. It makes logical sense to only breed from those dogs that are healthy – even if the stats don’t always back us up here!</li>
            <li>Best of breed parents. We feel that a well bred Labradoodle needs to start off, in the first instance, with the initial selection of parents to be described as good examples of their breed, from the Poodle world and the Labrador world. The idea is to negate carrying through unknown problems that may have resulted if we had bred from any old Labrador and Poodle. So when choosing a Labradoodle we would urge you to ask to see their lineage.</li>
            <li>Interbreeding. When we were looking into the breeding program of our Labradoodles we were struck by the concept of ‘hybrid vigour’. It made sense to us that if you take two entirely unconnected gene pools, such as the Labrador and Poodle, the resulting pups have been proven to grow into much healthier and stronger dogs. Having a Labradoodle from MagicLabradoodles means you are getting a dog that is not plagued by interbreeding issues.</li>
            <li>Diet. When the puppies are born they need to be fed a diet that closely relates to what they would eat in the wild – Weetabix will just not do! As they get older a diet that follows this philosophy is important to ensure that they are kept in the best possible health. So goes the saying you are what you eat!</li>
            <li>Exercise. Puppies should be exercised very gently, if at all, while their young bones are growing. Any damage to these developing bones tend to be permanent and can lead to problems in the future.</li>
          </ul>
          <p>When choosing a breeder please check that they have taken the precautions mentioned above. Unfortunately there are no guarantees that your puppy won’t end up with health problems sometime in the future. However, there is a much greater chance of having a healthy dog if the above precautions are followed in the breeding of your puppy.</p>
          <p>The main reason families choose a Labradoodle is for their amazing temperament, the second most popular reason is from those suffering from allergies. When choosing a breeder it is important to let them know the reason you have selected a labradoodle and they will help match you to the right puppy for you family.</p>
          <p>Asking for your puppy’s paper work and chatting to the breeder will give you an idea of where your puppy has come from and how he/she is being looked after.</p>
          <p>So if you would like a family dog that is healthier than most, coupled with its distinctive, lovable, family friendly, temperament then the Labradoodle is certainly worth looking at.</p>
        </div>
      </div>
    </div>
  );
}
import React, { useState } from "react";
import "./Content.css";
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignUp, AmplifySignOut } from '@aws-amplify/ui-react';
import { Alert, Card, Form, Button, Row, Col } from "react-bootstrap";
import Switch from "react-switch";
import { API, Storage, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';

export default function Admin() {
  const [AutoReplyActive, setAutoReplyActive] = useState(false);
  const [arId, setArID] = useState();
  const [DogGuruActive, setDogGuruActive] = useState(false);
  const [dgId, setDgID] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [validated, setValidated] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [errorTxt, setErrorTxt] = useState("Place holder messaage.");
  const [successMsg, setSuccessMsg] = useState(false);

  const successStyle = successMsg ? {} : { display: 'none' };
  const errorStyle = errorMsg ? {} : { display: 'none' };

  // Sample for creating the initial status
  // mutation create {
  //  createStatus(input:{
  //    name: "autoreply",value, "true"})
  //  }

  // const getStatus = /* GraphQL */ `
  //   query GetStatus($id: ID!) {
  //     getStatus(id: $id) {
  //       id
  //       name
  //       value
  //       createdAt
  //       updatedAt
  //     }
  //   }
  // `;

  const arStatus = `
  query  {
    listStatuss(filter: {
      name:{
        contains: "autoreply"
      }
    }) {
      items {
        id
        name
        value
      }
    }
  }`;

  const dgStatus = `
  query  {
    listStatuss(filter: {
      name:{
        contains: "dogguru"
      }
    }) {
      items {
        id
        name
        value
      }
    }
  }`;

  // Load initial state
  React.useEffect(() => {
    (async function () {
      const status = await API.graphql(graphqlOperation(arStatus));
      // console.log("GraphQL data (AutoReply): ",status.data.listStatuss.items[0].value);
      setAutoReplyActive(status.data.listStatuss.items[0].value)
      setArID(status.data.listStatuss.items[0].id)
    })();
    (async function () {
      const status = await API.graphql(graphqlOperation(dgStatus));
      // console.log("GraphQL data (DogGuru): ",status.data.listStatuss.items[0].value);
      setDogGuruActive(status.data.listStatuss.items[0].value)
      setDgID(status.data.listStatuss.items[0].id)
    })();
    // eslint-disable-next-line
  }, [])

  const onToggleAutoReply = (event) => {
    // console.log("AutoReply is: ", AutoReplyActive ? "Disabled" : "Enabled")
    setAutoReplyActive(!AutoReplyActive);
    // console.log("Ar Id: ",arId);
    API.graphql(graphqlOperation(mutations.updateStatus, { input: { id: arId, value: !AutoReplyActive } }));
  };

  const onToggleDogGuru = (event) => {
    // console.log("DogGuru is: ", DogGuruActive ? "Disabled" : "Enabled")
    setDogGuruActive(!DogGuruActive);
    // console.log("Dg Id: ",dgId)
    API.graphql(graphqlOperation(mutations.updateStatus, { input: { id: dgId, value: !DogGuruActive } }));
  };

  function uploadDogguru(event) {
    event.preventDefault();
    event.stopPropagation();
    Storage.put('dogguru.temp.xlsx', selectedFile, {
      level: 'private',
      contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
      .then(result => {
        console.log(result)
        setSuccessMsg(true)
        setErrorMsg(false)
        setValidated(false)
      })
      .catch(err => {
        console.log(err)
        setSuccessMsg(false)
        setErrorMsg(true)
        setErrorTxt(err)
        setValidated(false)
      });
  }

  function changeHandler(event) {
    setSelectedFile(event.target.files[0])

    if (event.target.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      setErrorMsg(false)
      setValidated(true)
    } else {
      setErrorMsg(true)
      setErrorTxt("File is not an Excel spreadsheet!")
      setValidated(false)
    }
  }

  return (
    <AmplifyAuthenticator hide="sign-up">
      <AmplifySignIn headerText="" slot="sign-in" />
      <AmplifySignUp headerText="Signup is not permitted at this time."
        slot="sign-up"
        usernameAlias="email"
        formFields={[
        ]}
      />
      <div className="Home">
        <div className="lander">
          <div className="labtext">
            <p>Note: Changes on this page take immediate effect</p>
            <Alert variant="success" style={successStyle}>
              <Alert.Heading>File uploaded successfully!</Alert.Heading>
              <p>Please check your email for any problems with processing.</p>
            </Alert>
            <Alert variant="danger" style={errorStyle}>
              <Alert.Heading>Error!</Alert.Heading>
              <p>{errorTxt}</p>
            </Alert>
            <Card>
              <Card.Body>
                <Form onSubmit={uploadDogguru}>
                  <div className="mb-3">
                    <Form.File id="formcheck-api-regular">
                      <Row >
                        <Col sm={3}>
                          <Form.File.Label>DogGuru spreadsheet</Form.File.Label>
                          <Form.File.Input onChange={changeHandler} />
                          <Form.Control.Feedback type="valid">You did it!</Form.Control.Feedback>
                        </Col>
                        <Col>
                          <Form.Group controlId="submitButton">
                            <Button variant="primary" type="submit" disabled={!validated}>
                              Upload
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.File>
                  </div>
                </Form>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Form>
                  <label>
                    <span><div className="labtext">Send AutoReply email for contact form requests.</div>
                      <Switch onChange={onToggleAutoReply} checked={AutoReplyActive} /></span>
                  </label>
                </Form>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Form>
                  <label>
                    <span><div className="labtext">Enable the daily DogGuru message.</div>
                      <Switch onChange={onToggleDogGuru} checked={DogGuruActive} /></span>
                  </label>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div><AmplifySignOut /></div>
      </div >
    </AmplifyAuthenticator>
  );
}


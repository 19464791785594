import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./Content.css";
import { Container, Row, Col, Button, Alert, Image, Form } from "react-bootstrap";
import logo from '../images/AnimatedLogo.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { API } from 'aws-amplify';

export default function Contact() {
    const initialFormData = Object.freeze({
        name: "",
        email: "",
        message: ""
    });

    const [formSent, setFormSent] = useState(false);
    const [validated, setValidated] = useState(false);
    const [human, setHuman] = useState(false);
    const [formData, updateFormData] = React.useState(initialFormData);
    const [showAlert, setShowAlert] = useState(false);

    const apiName = 'apilabradoodle';  
    const path = '/messages';  
    const myInit = {
        body: {},  
        headers: {},  
    };

    const formStyle = formSent ? { display: 'none' } : {};
    const thanksStyle = formSent ? {} : { display: 'none' };

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        });
    };

    const handleSubmit = (event) => {
        callApi(formData);
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    };

    function callApi(fd) {
        myInit.body = fd
        console.log('data:', fd)
        API
            .post(apiName, path, myInit)
            .then(response => {
                console.log(response)
                setFormSent(true)
            })
            .catch(error => {
                setShowAlert(false);
                console.log(error.response);
                setFormSent(true)
            });
    }

    function onExpired(value) {
        setHuman(false);
    }

    function onErrored(value) {
        setHuman(false);
    }

    function onChange(value) {
        setHuman(true);
    }

    function isHuman() {
        return human;
    }

    return (
        <div className="Home">
            <div className="lander">
                <Container>
                    <Row align="center">
                        <Col>
                            <h2>Contact Us</h2>
                        </Col>
                    </Row>
                    <Row align="center">
                        <Col><p />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <h3>We would love to hear from you.</h3>
                            </Row>
                            <Row>
                                <FontAwesomeIcon className="labtext" icon={faPhone} /><div className="labtext">&nbsp;+27 64 602 3157</div>
                            </Row>
                            <Row>
                                <FontAwesomeIcon className="labtext" icon={faEnvelope} /><div className="labtext">&nbsp;info@labradoodles.co.za</div>
                            </Row>
                            <Row>
                                <Image src={logo} fluid />
                            </Row>
                        </Col>
                        <Col sm={1}></Col>
                        <Col sm={6} style={thanksStyle} >
                            <   Alert variant="success">
                                <Alert.Heading>Thank You!</Alert.Heading>
                                <p>We'll be in touch shortly</p>
                            </Alert>
                        </Col>
                        <Col sm={6} style={formStyle}>
                            <Alert show={showAlert} variant="danger" onClose={() => setShowAlert(false)} dismissible>
                                <Alert.Heading>Oh snap! Unable to send contact message!</Alert.Heading>
                                <p>Sorry about that, Please try again shortly!</p>
                            </Alert>
                            <Form noValidate validated={validated} onSubmit={handleSubmit} action="">
                                <Form.Group controlId="nameInput">
                                    <Form.Control required type="text" name="name" placeholder="Name" onChange={handleChange} />
                                </Form.Group>
                                <Form.Group controlId="emailInput">
                                    <Form.Control required type="email" name="email" placeholder="Email Address" onChange={handleChange} />
                                </Form.Group>
                                <Form.Group controlId="messageInput">
                                    <Form.Control required as="textarea" rows="5" name="message" placeholder="Message" onChange={handleChange} />
                                </Form.Group>
                                <Form.Group controlId="captcha">
                                    <ReCAPTCHA 
                                        sitekey="6Lc_YKMZAAAAAMpHHMExLNLB6AhdSGltcwhB2VRR"
                                        onChange={onChange}
                                        onExpired={onExpired}
                                        onErrored={onErrored}
                                    />
                                </Form.Group>
                                <Form.Group controlId="submitButton">
                                    <Button variant="primary" type="submit" disabled={!isHuman()}>
                                        Submit
                            </Button>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div >
    );
}